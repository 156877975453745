import Link from '../Link'

interface DashCardProps {
	faIcon?: string
	label: string
	url?: string
	color: string
	imageUrl?: string
}

const DashCard = ({ faIcon, label, url, color, imageUrl }: DashCardProps) => {
	return (
		<Link href={`/${url ? url : label}`}>
			<div className='col-6'>
				<div className='card ripple'>
					<div className='p-lg'>
						{faIcon ? (
							<i className={'fa-4x orange ' + faIcon + ' ' + color}></i>
						) : (
							<img src={imageUrl} alt={label} height='64px' />
						)}
					</div>
					<hr className='hr light' />
					<hr className='hr light' />
					<div className='p-sm xs bold'>
						<span className='mr-sm upper'>{label}</span>
						<i className='fas fa-chevron-right'></i>
					</div>
				</div>
			</div>
		</Link>
	)
}

export default DashCard
