import { NextPage } from 'next'
import Page from '../layouts/Page'
import DashCard from '../components/home/DashCard'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import router from 'next/router'
import { RootState } from '../redux/store'

const IndexPage: NextPage = () => {
	const deviceType = useSelector(
		(state: RootState) => state.appState.deviceType
	)

	useEffect(() => {
		if (!deviceType) {
			router.push('/select-device-type')
		}
	}, [])

	return (
		<Page>
			<section>
				<div className='md bold pv-md p-sm'>INBOUND</div>
				<div className='grid gutter-lg center'>
					<DashCard faIcon='fas fa-pallet' color='orange' label='putaway' />
				</div>
			</section>

			<section>
				<div className='md bold pv-md p-sm mt-xl'>OUTBOUND</div>
				<div className='grid gutter-lg center'>
					<DashCard faIcon='fas fa-box-open' color='blue' label='packaging' />
					<DashCard faIcon='fas fa-truck' color='orange' label='dispatch' />
				</div>
			</section>

			<section>
				<div className='md bold pv-md p-sm mt-xl'>INVENTORY</div>
				<div className='grid gutter-lg center'>
					<DashCard
						faIcon='fas fa-exchange-alt'
						color='green'
						label='transfer'
					/>
					<DashCard label='iat' color='#67A4FF' imageUrl='/images/iat.svg' />
					<DashCard
						faIcon='fas fa-boxes'
						color='blue'
						label='audit'
						url='new-audit'
					/>
				</div>
			</section>

			<section>
				<div className='md bold pv-md p-sm mt-xl'>HELP</div>
				<div className='grid gutter-lg center'>
					<DashCard imageUrl='/images/help.png' color='blue' label='help' />
				</div>
			</section>
		</Page>
	)
}

export default IndexPage
